<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-btn rounded color="success" @click="downloadSelection()" :disabled="loading" :loading="loading">
                        <v-icon left dark>mdi-content-save</v-icon>
                        <span class="hidden-sm-and-down">Télécharger le document</span>
                    </v-btn>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</template>

<script>


export default {
    name: 'gan_download',
    props: [], 
    data: function () {
        return {
            loading: false,
            validKey: false,
            linkKey: ''
        }
    },
    mounted:function (){
        if( this.$route.params.id !== undefined && this.$route.params.id.length > 10 ){
            this.linkKey = this.$route.params.id
            this.validKey = true
        } else {
            this.validKey = false
        }
        this.downloadSelection()
    },
    methods: {
        downloadSelection(){
            if( this.validKey ){
                this.loading = true
                this.$http.customRequest({ method: 'post', url: '/foncea/documents/download/', data: { linkKey: this.linkKey, per_page:false }, responseType: 'blob' } )
                .then( (response) => {
                    let filename      = 'd.zip'
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(response.headers['content-disposition']);
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                    }
                    if( response.status == 204 ){
                        this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                    } else {
                        this.$download(response.data, filename, response.headers['content-type'])
                    }
                    this.loading = false
                }).catch( (error) => {
                    this.loading = false
                    this.$root.$toast({ color: 'error', text: error })
                })
            } else {
                this.$root.$toast({ color: 'error', text: 'Selectionner au moins un document' })
            }
        },
    },
    components: { }
}
</script>