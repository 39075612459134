<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" color="barrinfo">
        <v-toolbar-title >Planning</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small outlined class="mr-4" color="grey darken-2" @click="focus=''" >Aujourd'hui</v-btn>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="grey darken-2" v-bind="attrs" v-on="on" >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in types">
              <v-list-item @click="type = item.value" :key="index">
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn small outlined class="ml-2" color="grey darken-2" @click="getEvents(startEnd)" ><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
  <v-card class="ma-4 mb-2">
    <v-sheet tile height="54" class="d-flex">
      <v-toolbar dense flat>
        <v-toolbar-title v-if="ismounted">
          <v-menu ref="menu" v-model="monthSelector" :close-on-content-click="false" 
            transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ $refs.calendar.title }}&nbsp;<v-icon>mdi-chevron-double-down</v-icon>&nbsp;</div>
            </template>
            <v-date-picker v-model="dateSelector" @change="focus = $date(dateSelector).format('YYYY-MM-DD')" type="month" class="mt-4"></v-date-picker>
          </v-menu>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()"><v-icon>mdi-chevron-right</v-icon></v-btn>        
        <!-- <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2" label="type"></v-select> -->
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar" v-model="focus" :weekdays="weekday" :type="type" :events="events" :event-overlap-mode="mode" 
        :event-overlap-threshold="30" :event-color="getEventColor" @change="setDatePicker" @click:event="showEvent"
        @click:more="showMoreEvent"> <!-- @click:date="createEvent" -->

        <template v-slot:event="{event}">
          <div :title="event.title">{{ event.name }}</div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-card>
  <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
      <v-card color="grey lighten-4" min-width="350px" flat >
      <v-toolbar color="primary" dark class="elevation-1" height="50px">
          <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
          <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="eveInfo.trs_id" text color="" class="mr-5" :href="'/#/planning/site/' + eveInfo.trs_id" @click.native="switchModal('modalEvent', 0)">
            <v-icon>mdi-format-list-checks</v-icon>Fiche
          </v-btn>
          <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
          <!-- <edit-event class="elevation-2" 
              v-if="modalEvent" :switchModal="switchModal"
              :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
              :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
              :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates"
              :start="selectionEvent.start" :virutalEvent="virutalEvent">
          </edit-event> -->
          <edit-event class="elevation-0" 
              v-if="modalEvent" :switchModal="switchModal" :virutalEvent="virutalEvent"
              :xxxId="eveInfo.eve_xxx_id" 
              :eveId="eveInfo.eve_id" :eveTable="(eveInfo.eve_table_id) || 'trs'" :eveType="(eveInfo.eve_typ_id) ? eveInfo.eve_typ_id.substring(0, 3) : eveInfo.eve_typ_id"
              :sheetType="(eveInfo.trs_typ_id) ? eveInfo.trs_typ_id : false">
          </edit-event>
      </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="modalListEvent" max-width="900" style="z-index:2066;">

  </v-dialog>
</v-container>
</template>

<script>
import dateLocale        from '../services/dateLocale';
import { eveObj } from '../mixins/eve_obj.js'
import { cacheData, cacheGetters } from "../store/cache.module"
import editEvent  from '../components/Edit_event.vue'

export default {
  name: 'calendar',
  mixins: [ eveObj ],
  props: [],
  data: () => ({
    loading: { getItem: true, getUnplanned: false },
    locDateFormat: dateLocale, // Format de date
    type: 'month',
    typeToLabel: { month: 'Mois', week: 'Semaine', day: 'Jour', '4day': '4 Jours', },
    types: [{ text: 'Mois', value: 'month' }, { text: 'Semaine', value: 'week' }, { text: 'Jour', value: 'day' }, { text: '4 Jours', value: '4day' },],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Lun - Dim', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Dim - Sam', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Ven', value: [1, 2, 3, 4, 5] },
    ],
    monthSelector: false,
    dateSelector: null,
    focus: '',
    events: [],
    eventDay: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Réunion', 'Preventif', 'test', 'Déplacement', 'Évènement', 'Absence', 'Conference', 'tache'],
    modalEvent    : false,
    modalListEvent: false,
    selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ACT", title:"Actions", cooridnates:null, start:null },
    selectedEvent: {},
    selectedElement: null,
    selectedMore: false,
    moreDate: '',
    startEnd: null,
    ismounted: false,
    computeEvents: [],
    virutalEvent: {},
    eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 'trs', eve_xxx_id  : 0, eve_typ_id: '', trs_typ_id: '', trs_id: 0},
  }),
  computed: {
    user(){
        return cacheData.user
    },
  },
  mounted:function (){
    this.dateSelector = this.$date().format('YYYY-MM-DD')
    this.focus = ''
    this.ismounted = true
  },
  methods: {
    async getEvents ( { start, end } ) { //{ start, end }
      this.startEnd  = { start, end }
      this.events  = []

      await this.getEventIndex(false, false, '?per_page=false&min_eve_date_start=' + start.date + '&max_eve_date_start=' + end.date + '&eve_table_id=trs&extended=withtrackingsheet' )
      await this.computeEventList()
      await this.getEventIndex(false, false, '?per_page=false&min_eve_date_start=' + start.date + '&max_eve_date_start=' + end.date + '&eve_table_id=spf&extended=withtrackingsheet' )
      await this.computeEventList()
      await this.getEventIndex(false, false, '?per_page=false&min_eve_date_start=' + start.date + '&max_eve_date_start=' + end.date + '&eve_table_id=tcv&extended=withtrackingsheet' )
      await this.computeEventList()
    },
    computeEventList(){
      return new Promise( (resolve) => {
        const events = []
        const types  = this.getCacheType()
        this.listEvent.forEach( (obj) => {  
            let type = obj.eve_typ_id.substring(0, 3)
            let title = (obj.eve_title.length) ? obj.eve_title : obj.eve_description
            title     = (obj.trs_coddt) ? '[' + obj.trs_coddt + '] ' + title : title
            events.push({
              name: title,
              start: new Date(obj.eve_date_start), //end: second,
              color: (obj.eve_typ_id_rec !== 'REC01') ? 'orange' : types[type][obj.eve_typ_id]['typ_color'], //this.colors[this.rnd(0, this.colors.length - 1)],
              eve_id       : obj.eve_id,
              eve_table_id : obj.eve_table_id,
              eve_typ_id   : obj.eve_typ_id,
              title        : obj.trs_name,
              eveObj       : obj
            })
        })
        this.events.push(...events)
        resolve('resolved')
      })
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    switchModal(name, mode){
        name    = name || 'dialogObj'
        mode    = mode || false
        //refresh = refresh || false
        this[name] = mode
        if( !mode ){
          this.getEvents(this.startEnd)
        }
    },
    showEvent(event){
      this.virutalEvent         = ( event.event.eve_typ_id_rec !== 'REC01' ) ? event.event.eveObj : {} 
      this.eveInfo.eve_id       = event.event.eveObj.eve_id
      this.eveInfo.eve_table_id = event.event.eveObj.eve_table_id
      this.eveInfo.eve_xxx_id   = event.event.eveObj.eve_xxx_id
      this.eveInfo.eve_typ_id   = event.event.eveObj.eve_typ_id
      this.eveInfo.trs_typ_id   = (event.event.eveObj.trs_typ_id) ? event.event.eveObj.trs_typ_id : ''
      this.eveInfo.trs_id       = (event.event.eveObj.trs_id) ? event.event.eveObj.trs_id : 0

      this.switchModal('modalEvent', 1)
    },
    async showMoreEvent(info){
      this.eventDay = []
      //await this.getEventIndex( false, false, '?per_page=false&min_eve_date_start=' + info.date + '&extended=withtrackingsheet' )
      //this.eventDay = [...this.listEvent]
      await this.getEventIndex( false, false, '?per_page=false&min_eve_date_start=' + info.date + '&eve_table_id=spf' )
      this.eventDay = [...this.listEvent]

      const open = () => {
        this.moreDate        = info.date
        this.selectedElement = info.nativeEvent.target
        setTimeout(() => this.selectedMore = true, 10)
      }
      if (this.selectedMore) {
          this.selectedMore = false
          setTimeout(open, 10)
      } else {
        open()
      }
    },
    setDatePicker({ start, end }){
      if( this.focus !== '' ){
        this.dateSelector = this.$date(start.date).format('YYYY-MM-DD')
      } else {
        this.dateSelector = this.$date(start.date).format('YYYY-MM-DD')
      }
      this.getEvents({ start, end })
    },
    getCacheType: cacheGetters.getTypes,
  },
  components: {
      'edit-event'   : editEvent
  }
}
</script>