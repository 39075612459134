<template>
<v-card>
    <v-card-text>
        <template>
        <v-data-table :headers="fileHeaders" :items="items" hide-default-footer class="elevation-1" :item-class="itemRowBackground">
            <template v-slot:[`item.valid`]="{ item }" :color="'grey'">
                <v-icon aria-hidden="false" :color="item.color">{{item.icon}}</v-icon>
            </template>
        </v-data-table>
        </template>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="success" @click="sendDocument()" :loading="loadingData" :disabled="sendingData">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalAddDoc')" :disabled="loadingData">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import { toolHelper }  from '../mixins/tool_helper.js'

export default {
    name: 'gandocumentedit',
    props: ['switchModal', 'documentFile', 'getDocument'],
    mixins: [ toolHelper ],
    data: function () {
        return {
            loadingData: true,
            sendingData: true,
            fileHeaders:[
                { text: 'Bureau de conservation', align: 'left', value: 'bureau_conservation'},
                { text: 'Ref. Hypothèque',        align: 'left', value: 'ref_hypotheque'},
                { text: 'Nom du fichier',         align: 'left', value: 'file_path'},
                { text: 'Taille',                 align: 'left', value: 'taille_document'},
                { text: 'Valide',                 align: 'left', value: 'valid'},
            ],
            items: [],
        }
    },
    //beforeMount: function(){ },
    mounted:function (){
        this.checkDocument()
    },
    methods: {
        async checkDocument () {
            this.sendingData   = false
            let documents = []
            let reext = /(?:\.([^.]+))?$/;
            //this.documentFile.forEach( (element) => {
            for (let element of this.documentFile) {
                let ext        = reext.exec(element.name)[1]
                let baseName   = element.name.split('.').slice(0, -1).join('.')
                let valid      = false
                let icon       = 'mdi-close-octagon'
                let color      = 'red'
                let message    = ''
                let fields     = element.name.replace(/\.[^/.]+$/, "").split('_')
                let size       = Math.round(element.size / 1000)
                let sizeType   = 'Ko'
                let specialChar= false
                if (new RegExp(["á","à","ã","â","é","è","ê","í","ì","î","õ","ó","ò","ô","ú","ù","û", " "].join("|")).test(baseName)) {
                    specialChar= true
                }
                if( element.size > 1000000 ){
                    size     = Math.round( element.size / 1000000 )
                    sizeType = 'Mo'
                }
                // file controle
                let duplicate = await this.getDocumentByRef(element.name)
                if( fields.length === 2 && ext == 'pdf' && !specialChar ){
                    valid = true
                    icon  = 'mdi-check-bold'
                    color = 'green'
                } else {
                    fields[1] = 'FICHIER NON VALIDE'
                }
                if( duplicate.length > 0 ) {
                    fields[1] = 'DOUBLON'
                    valid = false
                    icon  = 'mdi-close-octagon'
                    color = 'red'
                }
                documents.push( 
                    { 
                        bureau_conservation : fields[0], ref_hypotheque : fields[1], file_path : element.name, 
                        taille_document : size + ' ' + sizeType, valid: valid, icon: icon, text: message, class:'', color:color
                    }
                )
            }
            this.loadingData = false
            this.items = documents
        },
        getDocumentByRef (ref) {
            return new Promise( (resolve) => {
                var searchCriteria = '?gdo_filename=' + ref
                this.$http.get( '/foncea/documents/' + searchCriteria ).then( (response) => {
                    resolve(response.data.data)
                }).catch( () => { 
                    resolve([1])
                })
            })
        },
        async sendDocument () {
            this.loadingData = true
            this.sendingData = true
            let message = '';
            // Enregistrement du formulaire
            let i = 0
            for (let element of this.documentFile) {
                if(this.items[i].valid){
                    message += await this.saveDocument(element)
                    message += "\n"
                    this.items[i].class = 'backgroung_green'
                    this.items[i].icon  = 'mdi-send'
                } else {
                    this.items[i].class = 'backgroung_red'
                }
                //this.items.shift()
                i++
            }
            this.$root.$toast({ color: 'success', text: message, timeout: 6000 })
            this.loadingData = false
            //this.switchModal('modalAddDoc')
            this.getDocument()
        },
        saveDocument (element) {
            return new Promise( (resolve, reject) => {
                if( element == undefined ){
                    reject( 'error' )
                } else {
                    var formFile = new FormData()
                    if( element ) {
                        formFile.append('gan_document', element, element.name)
                    }
                    this.$http.post( '/foncea/documents', formFile )
                    .then( () => {
                        resolve( 'Document ' + element.name + ' envoyé !' )
                    }).catch( () => {
                        resolve('Document ' + element.name + ' non envoyé !') //reject(error)
                    })
                }
            })
        },
        itemRowBackground: function (item) {
            return item.class
        },

    },
    components: { }
}
</script>

<style lang="css">
.backgroung_red{
    background-color: #FFCDD2;
}
.backgroung_green{
    background-color: #DCEDC8;
}
</style>