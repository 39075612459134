<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-shape-polygon-plus</v-icon></v-btn>
                    Importer de nouvelles parcelles
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <span class="caption">Dernier export :</span> -->
                <v-btn rounded outlined color="white" class="mr-2 white--text" title="Télécharger la liste" @click="downloadPlotList()">
                    <v-icon dark>mdi-file-excel</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-file-input v-model="form.file_plot" chips label="Selectionner un fichier"></v-file-input>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="sendSynchFile('file_plot')" :disabled="saveProgress" >
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs12 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-alert border="top" colored-border type="info" elevation="2" >
                <v-list dense>
                    <v-subheader v-if="processInfo.error_file">
                        {{processInfo.insert}} sur {{processInfo.source}} lignes, enregistrées dans la base
                        <!-- <v-btn rounded color="success" :disabled="false" @click="downloadError()">
                            <v-icon left dark>mdi-content-save</v-icon>
                            <span class="hidden-sm-and-down">Telecharger le rapport</span>
                        </v-btn> -->
                    </v-subheader>
                    <v-subheader v-else>RAPPORT</v-subheader>
                    <v-list-item-group color="primary" >
                        <v-list-item v-for="(item, i) in processInfo.error" :key="i" >
                        <v-list-item-content>
                            <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-alert>
            </v-card>
    </v-flex>
</v-layout>
</v-container>
</template>

<script>
//import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'site_report',
    props: [], 
    data: function () {
        return {
            saveProgress : false,
            loading: { getItem: false },
            repLoading: false,
            repDiag: false,
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
            form: { file_plot: null },
            processInfo: { error : [ 'Information sur le traitement...' ]},
        }
    },
    mounted:function (){
       
    },
    methods: {
        sendSynchFile() {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            //for (var prop in this.form) {
            //    if( prop == scope ){
            //        formFile.append(prop, JSON.stringify( this.form[prop] ))
            //    } else {
            //        formFile.append(prop, this.form[prop])
            //    }
            //}
            formFile.append('file_plot', this.form.file_plot, this.form.file_plot.name)

            this.$http.post( '/foncea/plot/import/', formFile )
            .then( (response) => {
                this.processInfo = response.data['file_plot']
                this.$root.$toast({ color: 'success', text: 'Données enregistrées !' })
                this.saveProgress = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })
        },
        downloadPlotList(){
            let searchCriteria  = '?order_by=gpl_id&order_direction=desc&per_page=false'
            this.$http.customRequest({ method: 'get', url: '/foncea/plot/xlsx/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "liste_parcelles.csv", "text/csv")
            })
        },
    },
    components: { }
}
</script>