var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"barrinfo"}},[_c('v-toolbar-title',[_vm._v("Planning")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","outlined":"","color":"grey darken-2"},on:{"click":function($event){_vm.focus=''}}},[_vm._v("Aujourd'hui")]),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_vm._l((_vm.types),function(item,index){return [_c('v-list-item',{key:index,on:{"click":function($event){_vm.type = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]})],2)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"grey darken-2"},on:{"click":function($event){return _vm.getEvents(_vm.startEnd)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card',{staticClass:"ma-4 mb-2"},[_c('v-sheet',{staticClass:"d-flex",attrs:{"tile":"","height":"54"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[(_vm.ismounted)?_c('v-toolbar-title',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.$refs.calendar.title)+" "),_c('v-icon',[_vm._v("mdi-chevron-double-down")]),_vm._v(" ")],1)]}}],null,false,121517718),model:{value:(_vm.monthSelector),callback:function ($$v) {_vm.monthSelector=$$v},expression:"monthSelector"}},[_c('v-date-picker',{staticClass:"mt-4",attrs:{"type":"month"},on:{"change":function($event){_vm.focus = _vm.$date(_vm.dateSelector).format('YYYY-MM-DD')}},model:{value:(_vm.dateSelector),callback:function ($$v) {_vm.dateSelector=$$v},expression:"dateSelector"}})],1)],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"change":_vm.setDatePicker,"click:event":_vm.showEvent,"click:more":_vm.showMoreEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{attrs:{"title":event.title}},[_vm._v(_vm._s(event.name))])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1),_c('v-dialog',{staticStyle:{"z-index":"2066"},attrs:{"max-width":"900"},model:{value:(_vm.modalEvent),callback:function ($$v) {_vm.modalEvent=$$v},expression:"modalEvent"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectionEvent.title))]),_c('v-spacer'),(_vm.eveInfo.trs_id)?_c('v-btn',{staticClass:"mr-5",attrs:{"text":"","color":"","href":'/#/planning/site/' + _vm.eveInfo.trs_id},nativeOn:{"click":function($event){return _vm.switchModal('modalEvent', 0)}}},[_c('v-icon',[_vm._v("mdi-format-list-checks")]),_vm._v("Fiche ")],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){return _vm.switchModal('modalEvent', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[(_vm.modalEvent)?_c('edit-event',{staticClass:"elevation-0",attrs:{"switchModal":_vm.switchModal,"virutalEvent":_vm.virutalEvent,"xxxId":_vm.eveInfo.eve_xxx_id,"eveId":_vm.eveInfo.eve_id,"eveTable":(_vm.eveInfo.eve_table_id) || 'trs',"eveType":(_vm.eveInfo.eve_typ_id) ? _vm.eveInfo.eve_typ_id.substring(0, 3) : _vm.eveInfo.eve_typ_id,"sheetType":(_vm.eveInfo.trs_typ_id) ? _vm.eveInfo.trs_typ_id : false}}):_vm._e()],1)],1)],1),_c('v-dialog',{staticStyle:{"z-index":"2066"},attrs:{"max-width":"900"},model:{value:(_vm.modalListEvent),callback:function ($$v) {_vm.modalListEvent=$$v},expression:"modalListEvent"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }