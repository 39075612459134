<template>
<v-container fluid class="pa-0">
    <v-card-text>
        <v-layout wrap style="background-color:white;" class="pa-2 elevation-1">
            <v-flex xs12 class="pb-2">
                <v-btn v-if="isAuthorized( 'GanDocumentPolicy', 'update' )" @click="browseDocument"
                    small color="green" class="mr-2 white--text" :disabled="loadingData">
                    <v-icon left color="white">mdi-pencil-plus</v-icon>Enregistrer un acte notarié
                </v-btn>
                <v-file-input v-show="false" accept=".pdf" id="sendmultifile" ref="sendfile" v-model="sendFiles" multiple @change="sendFileDocument" ></v-file-input>
                <v-btn small color="primary" class="mr-2 white--text" @click="downloadSelection()" :disabled="loadingData">
                    <v-icon left color="white">mdi-content-save-outline</v-icon>Télécharger la selection
                </v-btn>
                <v-btn v-if="isAuthorized( 'GanDocumentPolicy', 'destroy' )" class="mr-2 white--text" small color="red white--text" :disabled="loadingData" @click="deleteSelectionItem()">
                    <v-icon left color="white">mdi-delete-forever</v-icon>Supprimer la selection
                </v-btn>
            </v-flex>
            <v-flex xs12 class="pb-2">
                <v-divider class="mx-4" inset ></v-divider>
            </v-flex>
            <v-flex xs4 class="pb-2">
                <v-btn small color="grey lighten-1 white--text" class="mr-2" @click="createDownloadLink()" :disabled="loadingData">
                    <v-icon color="primary" left>mdi-link</v-icon>Générer l'URL de téléchargement
                </v-btn>
            </v-flex>
            <v-flex xs8 class="pb-2">
                <v-text-field ref="urlToShare" label="URL d'accès à la selection d'actes notariés" 
                    @click="copyUrlToClipboard()" @click:prepend="copyUrlToClipboard()"
                    v-model="urlShare" dense  clearable hide-details prepend-icon="mdi-content-copy">
                </v-text-field>
            </v-flex>
            <v-flex xs12 class="pb-2">
                <v-divider class="mx-4" inset ></v-divider>
            </v-flex>
            <!-- <v-flex xs4 class="pb-0 align-self-baseline">
                Filtrage par mots clés :
            </v-flex> -->
            <v-flex xs8 class="pb-0">
            <v-autocomplete label="Filtre par mots clés (touche ENTREE ou selectionner pour ajouter un mot, puis filtrer)"
                prepend-icon="mdi-clipboard-search-outline" hide-no-data
                v-model="filterValue.keywords" :items="autoCompleteSource.itemsKeywords" @input="addKeyword" 
                hide-selected hide-details chips small-chips multiple auto-select-first clearable :search-input.sync="keywordSearch">
            </v-autocomplete>
            </v-flex>
            <v-flex xs4 class="pl-2 pb-0">
                <v-radio-group v-model="filterValue.typekeyword" class="ma-0" dense row>
                    <v-radio label="Tous les mots" :value="'1'"></v-radio>
                    <v-radio label="Au moins un mot" :value="'0'" ></v-radio>
                </v-radio-group>
            </v-flex>
            <v-flex xs12 class="pb-1">
                <auto-multi :title="'Filtre sur le propriétaire (appuyer sur ECHAP pour fermer la fenêtre)'" ressource="foncea/plot/owner" :listObject.sync="filterValue.gpl_owner"
                    :listParent.sync="autoCompleteSource.gpl_owner" :fields="{ index: 'gpl_owner', text:'gpl_owner', search:'gpl_owner', multiSelect:true, preprendIcon:'mdi-account' }"
                    class="ml-0 mr-0" >
                </auto-multi>
            </v-flex>
            <v-flex xs3 class="pt-2">
                <v-autocomplete class="ml-0 mr-0" solo clearable
                    v-model="filterValue.gpl_town" :items="autoCompleteSource.gpl_town"  dense chips small-chips 
                    label="Ville" item-text="gpl_town" item-value="gpl_town"
                    @change="getFilterData('gpl_section')">
                </v-autocomplete>
            </v-flex>
            <v-flex xs2 class="pt-2">
                <v-autocomplete class="ml-0 mr-0" solo clearable :disabled="!filterValue.gpl_town"
                    v-model="filterValue.gpl_section" :items="autoCompleteSource.gpl_section"  dense chips small-chips 
                    label="Section" item-text="gpl_section" item-value="gpl_section"
                    @change="getFilterData('gpl_plot_id')">
                </v-autocomplete>
            </v-flex>
            <v-flex xs2 class="pt-2">
                <v-autocomplete class="ml-0 mr-0" solo clearable :disabled="!filterValue.gpl_section"
                    v-model="filterValue.gpl_plot_id" :items="autoCompleteSource.gpl_plot_id"  dense chips small-chips 
                    label="Parcelle" item-text="gpl_plot_id" item-value="gpl_plot_id">
                </v-autocomplete>
            </v-flex>
            <v-flex xs3 class="pt-2">
                <v-container fluid grid-list-xl >
                <v-row align="start">
                <v-col cols="12" sm="6" md="6" class="pl-2 pa-0">
                <v-menu ref="gpl_date_sign_start" v-model="dateMenu.gpl_date_sign_start"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" v-on="on" clearable @click:clear="filterValue.gpl_date_sign_start = null"
                            readonly label="Date acte début" dense :value="locDateFormat.formatLocale( filterValue.gpl_date_sign_start, 'fr' )">
                        </v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="filterValue.gpl_date_sign_start" @input="dateMenu.gpl_date_sign_start = false" locale="fr-fr" no-title scrollable>
                    </v-date-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="pl-2 pa-0">
                <v-menu ref="gpl_date_sign_end" v-model="dateMenu.gpl_date_sign_end"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" v-on="on" clearable @click:clear="filterValue.gpl_date_sign_end = null"
                            readonly label="Date acte fin" dense :value="locDateFormat.formatLocale( filterValue.gpl_date_sign_end, 'fr' )">
                        </v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="filterValue.gpl_date_sign_end" @input="dateMenu.gpl_date_sign_end = false" locale="fr-fr" no-title scrollable>
                    </v-date-picker>
                </v-menu>
                </v-col></v-row>
                </v-container>
            </v-flex>
            <v-flex xs2 class="text-right">
                <v-btn small color="green white--text" class="mr-2 mb-1" @click="getDocument()" :disabled="loadingData">
                    <v-icon left dark>mdi-filter</v-icon>Filtrer
                </v-btn>
                <v-btn small color="orange white--text" class="mr-2" @click="resetFilter()" :disabled="loadingData">
                    <v-icon left dark>mdi-refresh</v-icon>Annuler filtre
                </v-btn>
            </v-flex>
        </v-layout>
        <!-- <v-divider class="mx-4" inset ></v-divider> -->
    </v-card-text>

    <v-card class="ml-4 mr-4" > <!-- @wheel.prevent="wheelDatatable()" -->
    <v-data-table ref="documenttable" v-model="selected" item-key="gdo_id" show-select v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer dense @pagination="getFiltered"
        :loading="loadingData" loading-text="Chargement des données... veuillez patienter"
        @toggle-select-all="selectAllItem"> <!-- () => {selected = items} -->
        <template v-slot:top>
            <v-row align="start" justify="end">
                <v-col cols="6">
                <v-text-field label="Filtre général" prepend-inner-icon="mdi-filter" class="ml-3 mr-3"
                    clearable hide-details v-model="search" @blur="saveSearchToCache()" dense>
                </v-text-field>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-select :items="[5, 10, 15, 20, 30, 40, 50, 1000]" v-model="pagination.itemsPerPage" label="Nombre de lignes affichées" dense ></v-select>
                </v-col>
                <v-col class="text-right">
                <v-btn rounded outlined depressed text color="primary" class="mr-2 white--text" title="Télécharger la liste" @click="downloadList()">
                    <v-icon dark>mdi-file-excel</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`header.gdo_keep_office`]="{ header }">
            <v-menu 
                :close-on-content-click="false"
                v-model="filterMenu.gdo_keep_office" offset-y bottom right nudge-right transition="slide-y-transition" content-class="elevation-3" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" aria-hidden="false" :color="(filterTable.gdo_keep_office) ? 'red' : 'primary'">mdi-magnify-plus-outline</v-icon>{{ header.text }}
                </template>
                <v-card class="pa-2" style="background-color:white;">
                <v-text-field v-model="filterTable.gdo_keep_office" solo label="Entrer du texte" dense clearable></v-text-field>
                <v-alert border="left" colored-border type="info" class="caption"><span v-html="alertInfo.textFilter"></span></v-alert>
                <v-layout wrap style="background-color:white;">
                <v-flex xs6 class="pb-2"><v-btn small color='primary' @click="filterMenu.gdo_keep_office = false; getDocument();"><v-icon left>mdi-filter</v-icon>Appliquer</v-btn></v-flex>
                <v-flex xs6 class="pb-2"><v-btn small color='red' @click="filterMenu.gdo_keep_office = false; filterTable.gdo_keep_office = ''; getDocument();"><v-icon left>mdi-close</v-icon>Annuler</v-btn></v-flex>
                </v-layout>
                </v-card>
            </v-menu>
        </template>
        <template v-slot:[`header.gdo_mortgage_code`]="{ header }">
            <v-menu 
                :close-on-content-click="false"
                v-model="filterMenu.gdo_mortgage_code" offset-y bottom right nudge-right transition="slide-y-transition" content-class="elevation-3" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" aria-hidden="false" :color="(filterTable.gdo_mortgage_code) ? 'red' : 'primary'">mdi-magnify-plus-outline</v-icon>{{ header.text }}
                </template>
                <v-card class="pa-2" style="background-color:white;">
                <v-text-field v-model="filterTable.gdo_mortgage_code" solo label="Entrer du texte" dense clearable></v-text-field>
                <v-alert border="left" colored-border type="info" class="caption"><span v-html="alertInfo.textFilter"></span></v-alert>
                <v-layout wrap style="background-color:white;">
                <v-flex xs6 class="pb-2"><v-btn small color='primary' @click="filterMenu.gdo_mortgage_code = false; getDocument()"><v-icon left>mdi-filter</v-icon>Appliquer</v-btn></v-flex>
                <v-flex xs6 class="pb-2"><v-btn small color='red'     @click="filterMenu.gdo_mortgage_code = false; filterTable.gdo_mortgage_code = ''; getDocument();"><v-icon left>mdi-close</v-icon>Annuler</v-btn></v-flex>
                </v-layout>
                </v-card>
            </v-menu>
        </template>
        <template v-slot:[`header.gdo_filename`]="{ header }">
            <v-menu 
                :close-on-content-click="false"
                v-model="filterMenu.gdo_filename" offset-y bottom right nudge-right transition="slide-y-transition" content-class="elevation-3" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" aria-hidden="false" :color="(filterTable.gdo_filename) ? 'red' : 'primary'">mdi-magnify-plus-outline</v-icon>{{ header.text }}
                </template>
                <v-card class="pa-2" style="background-color:white;">
                <v-text-field v-model="filterTable.gdo_filename" solo label="Entrer du texte" dense clearable></v-text-field>
                <v-alert border="left" colored-border type="info" class="caption"><span v-html="alertInfo.textFilter"></span></v-alert>
                <v-layout wrap style="background-color:white;">
                <v-flex xs6 class="pb-2"><v-btn small color='primary' @click="filterMenu.gdo_filename = false; getDocument()"><v-icon left>mdi-filter</v-icon>Appliquer</v-btn></v-flex>
                <v-flex xs6 class="pb-2"><v-btn small color='red'     @click="filterMenu.gdo_filename = false; filterTable.gdo_filename = ''; getDocument();"><v-icon left>mdi-close</v-icon>Annuler</v-btn></v-flex>
                </v-layout>
                </v-card>
            </v-menu>
        </template>
        <template v-slot:[`header.gdo_user_login`]="{ header }">
            <v-menu 
                :close-on-content-click="false"
                v-model="filterMenu.gdo_user_login" offset-y bottom right nudge-right transition="slide-y-transition" content-class="elevation-3" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" aria-hidden="false" :color="(filterTable.gdo_user_login) ? 'red' : 'primary'">mdi-magnify-plus-outline</v-icon>{{ header.text }}
                </template>
                <v-card class="pa-2" style="background-color:white;">
                <v-text-field v-model="filterTable.gdo_user_login" solo label="Entrer du texte" dense clearable></v-text-field>
                <v-alert border="left" colored-border type="info" class="caption"><span v-html="alertInfo.textFilter"></span></v-alert>
                <v-layout wrap style="background-color:white;">
                <v-flex xs6 class="pb-2"><v-btn small color='primary' @click="filterMenu.gdo_user_login = false; getDocument()"><v-icon left>mdi-filter</v-icon>Appliquer</v-btn></v-flex>
                <v-flex xs6 class="pb-2"><v-btn small color='red'     @click="filterMenu.gdo_user_login = false; filterTable.gdo_user_login = ''; getDocument();"><v-icon left>mdi-close</v-icon>Annuler</v-btn></v-flex>
                </v-layout>
                </v-card>
            </v-menu>
        </template>
        <template v-slot:[`header.gdo_date_send`]="{ header }">
            <v-menu 
                :close-on-content-click="false"
                v-model="filterMenu.gdo_date_send" offset-y bottom right nudge-right transition="slide-y-transition" content-class="elevation-3" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" aria-hidden="false" :color="(filterValue.gdo_date_send_start || filterValue.gdo_date_send_end) ? 'red' : 'primary'">mdi-magnify-plus-outline</v-icon>{{ header.text }}
                </template>
                <v-card class="pa-2" style="background-color:white;">
                <v-menu ref="gdo_date_send_start" v-model="dateMenu.gdo_date_send_start"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" v-on="on" clearable @click:clear="filterValue.gdo_date_send_start = null"
                            readonly label="Date début" dense :value="locDateFormat.formatLocale( filterValue.gdo_date_send_start, 'fr' )">
                        </v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="filterValue.gdo_date_send_start" @input="dateMenu.gdo_date_send_start = false" locale="fr-fr" no-title scrollable>
                    </v-date-picker>
                </v-menu>
                <v-menu ref="gdo_date_send_end" v-model="dateMenu.gdo_date_send_end"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" v-on="on" clearable @click:clear="filterValue.gdo_date_send_end = null"
                            readonly label="Date fin" dense :value="locDateFormat.formatLocale( filterValue.gdo_date_send_end, 'fr' )">
                        </v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="filterValue.gdo_date_send_end" @input="dateMenu.gdo_date_send_end = false" locale="fr-fr" no-title scrollable>
                    </v-date-picker>
                </v-menu>
                <v-layout wrap style="background-color:white;">
                <v-flex xs6 class="pb-2"><v-btn small color='primary' @click="filterMenu.gdo_date_send = false; getDocument()"><v-icon left>mdi-filter</v-icon>Appliquer</v-btn></v-flex>
                <v-flex xs6 class="pb-2"><v-btn small color='red'     @click="filterMenu.gdo_date_send = false; filterTable.gdo_user_login = ''; getDocument();"><v-icon left>mdi-close</v-icon>Annuler</v-btn></v-flex>
                </v-layout>
                </v-card>
            </v-menu>
        </template>

        <template v-slot:[`item.gdo_filename`]="{ item }" :color="'grey'">
            <span class="caption">{{item.gdo_filename}}</span>
        </template>
        <template v-slot:[`item.gdo_date_send`]="{ item }" :color="'grey'">
            {{locDateFormat.formatLocale( item.gdo_date_send, 'fr' )}}
        </template>
        <template v-slot:[`item.gpl_date_sign_act`]="{ item }" :color="'grey'">
            {{locDateFormat.formatLocale( item.gpl_date_sign_act, 'fr' )}}
        </template>
        <template v-slot:[`item.gdo_size`]="{ item }" :color="'grey'">
            <span v-if="item.gdo_size > 1000">{{ Math.round(item.gdo_size / 1000) }} Mo</span>
            <span v-else>{{ Math.round(item.gdo_size) }} Ko</span>
        </template>
        <template v-slot:[`item.gdo_is_ocr`]="{ item }" :color="'grey'">
                <v-icon left title="Télécharger" color='primary' @click="downloadDocument(item.gdo_id)">mdi-content-save-outline</v-icon>
                <v-icon v-if="isAuthorized( 'GanDocumentPolicy', 'destroy' )" left color='red' @click="deleteItem(item.gdo_id)">mdi-delete-forever</v-icon>
                <!--<v-icon v-if="item.gdo_is_ocr" title="OCR" color="success" small class="ma-0">mdi-scanner</v-icon>
                <v-icon v-else title="OCR" color="grey" small class="mr-0">mdi-scanner-off</v-icon> -->
                <!-- <v-icon title="Modifier"   color="orange" small class="mr-1">mdi-pencil</v-icon> -->
        </template>

    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>Nombre total d'actes notariés: {{pagination.totalFiltered}} / {{pagination.totalItems}}</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination>
        </v-flex>
    </v-layout>

    <v-dialog v-model="modalAddDoc" max-width="900" style="z-index:3000;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="#43A047" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Envoyer un acte</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalAddDoc = !modalAddDoc"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-gandocuemnt class="elevation-2" 
                v-if="modalAddDoc" :switchModal="switchModal" :documentFile="sendFiles" :getDocument="getDocument">
            </edit-gandocuemnt>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'
import editGanDocument  from '../components/Edit_gan_document.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'document',
    props: ['filterView'],
    //mixins: [ docObj ],
    data () {
        return {
            modalAddDoc: false,
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 30, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            loadingData: false,
            headers: [
                { text: 'Bureau de conservation', align: 'left', value: 'gdo_keep_office'},
                { text: 'Ref. Hypothèque',        align: 'left', value: 'gdo_mortgage_code'},
                { text: 'Nom du fichier',         align: 'left', value: 'gdo_filename'},
                { text: 'Emetteur',                              value: 'gdo_user_login' },
                { text: 'Signature',                             value: 'gpl_date_sign_act' },
                { text: 'Enregistrement',                        value: 'gdo_date_send' },
                { text: 'Taille',                 align: 'left', value: 'gdo_size'},
                { text: 'Action',                 align: 'left', value: 'gdo_is_ocr', width: "120px"},
            ],
            selected: [],
            items: [],
            sendFiles: [],
            locDateFormat: dateLocale, // Format de date
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            filterMenu: {
                gdo_keep_office: false,
                gdo_mortgage_code: false,
                gdo_filename: false,
                gdo_user_login: false,
                gdo_date_send: false,
                gdo_size: false,
            },
            filterTable: {
                gdo_keep_office    : '',
                gdo_mortgage_code  : '',
                gdo_filename       : '',
                gdo_user_login     : '',
                gdo_size           : '',
            },
            dateMenu: { 
                gpl_date_sign_start: null,
                gpl_date_sign_end  : null,
                gdo_date_send_start: null,
                gdo_date_send_end  : null,
            },
            dateReport : {
                date_event_start    : null,
                date_event_end      : null,
            },
            autoCompleteSource:{
                gpl_town: [],
                gpl_section: [],
                gpl_plot_id: [],
                gpl_owner: [],
                itemsKeywords: [],
            },
            filterValue: {
                gpl_town: null,
                gpl_section: null,
                gpl_plot_id: null,
                gpl_owner: [],

                typekeyword: '1',
                keywords: [],
                gdo_date_send_start: null,
                gdo_date_send_end: null,
                gpl_date_sign_start: null,
                gpl_date_sign_end: null,
            },
            keywordSearch: '',
            urlShare: '',
            alertInfo: {
                textFilter: "Utilisez le caractère * (etoile) <br />pour remplacer un ou plusieurs caractères."
            },
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    watch: {
        keywordSearch (val) {
            if( val !== null && val !== undefined && val.length > 0 ){
                this.autoCompleteSource.itemsKeywords = []
                this.autoCompleteSource.itemsKeywords = this.autoCompleteSource.itemsKeywords.concat(this.filterValue.keywords)
                this.autoCompleteSource.itemsKeywords.push(val)
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('gandocuments')
        this.getDocument().then( () => {
            this.getFilterData('gpl_town')
        })
    },
    methods: {
        getFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            //let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=withplot'
            for( var prop in this.filterValue ){
                if( this.filterValue[prop] !== null && this.filterValue[prop] !== undefined && this.filterValue[prop].length > 0 ){
                    if( Array.isArray(this.filterValue[prop]) ){
                        searchCriteria += '&' + prop + '=' + this.filterValue[prop].join()
                    } else {
                        searchCriteria += '&' + prop + '=' + this.filterValue[prop]
                    }
                }
            }
            // Add Table filter
            for( prop in this.filterTable ){
                if( this.filterTable[prop] && Array.isArray(this.filterTable[prop]) ){
                    searchCriteria += '&' + prop + '=' + this.filterTable[prop].join()
                } else if( this.filterTable[prop] ) {
                    searchCriteria += '&' + prop + '=' + this.filterTable[prop].replaceAll('*', '%25')
                }
            }

            return searchCriteria
        },
        resetFilter(){
            for( var prop in this.filterValue ){
                if( this.filterValue[prop] !== null && this.filterValue[prop] !== undefined && this.filterValue[prop].length > 0 ){
                    if( Array.isArray(this.filterValue[prop]) ){
                        this.filterValue[prop] = []
                    } else {
                        this.filterValue[prop] = null
                    }
                }
            }
            for( prop in this.filterTable ){
                this.filterTable[prop] = ''
            }
            this.filterValue['typekeyword'] = '1'
            this.getDocument()
        },
        getDocument(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order   = order || ''
                page    = page  || 1
                perPage = perPage || false
                this.loadingData  = true
                this.listDocument = []
                this.items        = []
                this.selected     = []
                //var searchCriteria = '?page='+ this.pagination.page +'&per_page=false&order_by=gdo_date_send&order_direction=desc'
                var searchCriteria = this.getFilter()
                searchCriteria    += '&order_by=gdo_date_send&order_direction=desc'

                this.$http.get( '/foncea/documents' + searchCriteria ).then( (response) => {
                    this.metaDocument = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        //this.pagination.itemsPerPage = 2000
                        this.items = response.data.data
                    }
                    if( this.metaDocument.pagination.total !== undefined && this.metaDocument.pagination.total !== '0' ){
                        this.pagination.totalItems  = parseInt(this.metaDocument.pagination.total)
                    } else {
                        this.pagination.totalItems = 1
                    }
                    this.pagination.totalFiltered = this.pagination.totalItems
                    //this.items = this.listDocument
                    this.loadingData = false
                    resolve('resolved')
                }).catch( (error) => { 
                    this.loadingData = false
                    reject(error)
                })
            })
        },
        deleteItem(itemId){
            this.$root.$confirm('Confirmation de suppression', 'Êtes vous sûr de vouloir supprimer cet acte ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && itemId > 0 ){
                    this.$http.delete( '/foncea/documents/' + itemId ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Document supprimée' })
                        this.getDocument()
                    }).catch( (error) => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    })
                }
            })
        },
        deleteSelectionItem(){
            if( this.selected.length ){
                this.$root.$confirm('Supprimer ce(s) document(s)', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                    if( confirm ){
                        this.deleteMultipleItem()
                    }
                })
            } else {
                this.$root.$toast({ color: 'error', text: 'Veuillez sélectionner au moins un fichier', timeout: 5000 })
            }
        },
        async deleteMultipleItem(){
            this.loadingData = true
            for (const element of this.selected) {
                await this.$http.delete( '/foncea/documents/' + element.gdo_id ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Document #' + element.gdo_id + ' supprimée' })
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression #' + element.gdo_id + ' !\n' + error })
                })
            }
            this.getDocument()
        },
        getFilterData(type){
            type = type || null
            let searchCriteria = ''
            searchCriteria    += (type == 'gpl_section') ? '&gpl_town=' + this.filterValue.gpl_town : ''
            searchCriteria    += (type == 'gpl_plot_id') ? '&gpl_town=' + this.filterValue.gpl_town + '&gpl_section=' + this.filterValue.gpl_section : ''
            return new Promise( (resolve, reject) => {
                this.$http.get( '/foncea/plot/?per_page=false&group_by=' + type + '&order_direction=asc&order_by=' + type + searchCriteria ).then( (response) => {
                    if( type == 'gpl_town' ){
                        this.autoCompleteSource.gpl_town    = response.data.data
                    }
                    if( type == 'gpl_section' ){
                        this.filterValue.gpl_plot_id        = null
                        this.autoCompleteSource.gpl_plot_id = []
                        this.filterValue.gpl_section       = null
                        this.autoCompleteSource.gpl_section = []
                        this.autoCompleteSource.gpl_section = response.data.data
                    }
                    if( type == 'gpl_plot_id' ){
                        this.filterValue.gpl_plot_id        = null
                        this.autoCompleteSource.gpl_plot_id = []
                        this.autoCompleteSource.gpl_plot_id = response.data.data
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getDocument()
        },
        downloadDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: '/foncea/documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        downloadSelection(){
            if( this.selected.length ){
                this.loadingData = true
                let ids = []
                this.selected.forEach(element => { ids.push(element.gdo_id) })
                this.$http.customRequest({ method: 'post', url: '/foncea/documents/download/', data: { gdo_id: ids.join(), per_page:false }, responseType: 'blob' } )
                .then( (response) => {
                    let filename      = 'd.zip'
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(response.headers['content-disposition']);
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                    }
                    if( response.status == 204 ){
                        this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                    } else {
                        this.$download(response.data, filename, response.headers['content-type'])
                    }
                    this.loadingData = false
                }).catch( (error) => {
                    this.loadingData = false
                    this.$root.$toast({ color: 'error', text: error })
                })
            } else {
                this.$root.$toast({ color: 'error', text: 'Veuillez sélectionner au moins un fichier' })
            }
        },
        downloadList(){
            let searchCriteria = this.getFilter()
            searchCriteria    += '&order_by=gdo_date_send&order_direction=desc'
            this.$http.customRequest({ method: 'get', url: '/foncea/documents/xlsx/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "liste.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('gandocuments', this.search)
        },
        getFiltered(pagination){
            if( pagination.itemsLength !== undefined ){
                this.pagination.totalFiltered = pagination.itemsLength
            }
        },
        addKeyword(){
            this.autoCompleteSource.itemsKeywords = []
            this.autoCompleteSource.itemsKeywords = this.autoCompleteSource.itemsKeywords.concat(this.filterValue.keywords)
            this.keywordSearch = ''
        },
        createDownloadLink(){
            if( this.selected.length ){
                let ids = []
                this.selected.forEach(element => { ids.push(element.gdo_id) })
                this.$http.post( 'foncea/documents/link', { ids: ids.join() } ).then( (response) => {
                    this.urlShare = process.env.VUE_APP_URL + '#/gandownload/' + response.data.data
                })
            } else {
                this.$root.$toast({ color: 'error', text: 'Veuillez sélectionner au moins un fichier' })
            }
        },
        copyUrlToClipboard(){
            if( this.urlShare.length > 0 ){
                let textToCopy = this.$refs.urlToShare.$el.querySelector('input')
                textToCopy.select()
                document.execCommand("copy");
                this.$root.$toast({ color: 'success', text: 'Lien copié dans votre presse papier !' })
            }
        },
        browseDocument(){
            //this.$refs.sendfile.$el.click()
            document.getElementById('sendmultifile').click()
        },
        sendFileDocument(){
            if( this.sendFiles.length > 0 ){
                this.modalAddDoc = true
            } else {
                this.$root.$toast({ color: 'error', text: 'Veuillez sélectionner au moins un fichier' })
            }
        },
        switchModal(name, mode){
            name    = name || 'modalAddDoc'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
        },
        wheelDatatable(){
            //let self = this
            window.addEventListener('wheel', (event) => {
                console.log(event.deltaY)
                //if (event.deltaY < 0) {
                //    if( self.pagination.page > 1 ){
                //        self.pagination.page -= 1
                //    }
                //} else if (event.deltaY > 0) {
                //    if( self.pagination.page < self.pagination.pageCount ){
                //        self.pagination.page += 1
                //    }
                //}
            })
        },
        selectAllItem(){
            let search = (typeof this.search === 'string') ? this.search.trim() : null
            let headersWithoutCustomFilters = this.headers.filter(header => !header.filter && (!header.filterable || header.filterable === true))
            if( this.selected.length > 0 ){
                this.selected = []
            } else if( search ){
                search = search.toLowerCase()
                this.selected = this.items.filter( (item) => {
                    //const matchesSearchTerm = headersWithoutCustomFilters.some(this.filterFn(item, search))
                    let matchesSearchTerm = false
                    headersWithoutCustomFilters.forEach( (element) => {
                        let val = item[element.value] + ""
                        val = val.toLowerCase()
                        if( val.indexOf !== undefined && val.indexOf(search) > -1 ){
                            matchesSearchTerm = true
                        }
                    })
                    return matchesSearchTerm
                })
            } else {
                this.selected = this.items
            }
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        'auto-multi': autoCompMulti,
        'edit-gandocuemnt'   : editGanDocument
    }
}
</script>

<style lang="css">
.fixed_header{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 300px;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: right;
  width: 200px;
}
</style>